.search-bar-wrapper {
  .background-light,
  .border-light {
    transition: all .3s ease-in-out;
    width: calc(100% + 2px);
    height: 40px;
    position: absolute;
    border-radius: 26px;
    opacity: 0.6;
    z-index: 0;
    background: linear-gradient(91deg, #f60 0.2%, #76bd87 45.94%, #ff7dd7 100%);
    filter: blur(40px);
    @media (min-width: 768px) {
      height: 68px;
    }
    &.hidden{
      opacity: 0;
    }
  }
  .border-light {
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    z-index: 1;
    filter: none;
    border-radius: 8px;
  }
}

.search-category {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  .category-count {
    position: relative;
    background-color: #2672ec;
    color: #fff;
    font-size: 14px;
    line-height: 23px;
    padding: 0 5px;
    border-radius: 10px;
    .count-number{
      letter-spacing: 0;
    }
  }
}
