.item {
  padding-bottom: 10px;
  .title {
    position: relative;
    padding: 12px;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    overflow: hidden;
    border-radius: 12;
    &.fb {
      .process-bar {
        background-color: #662900;
        .process {
          background-color: rgba(255, 102, 0, 0.4);
        }
      }
    }
    &.ops {
      .process-bar {
        background-color: #2f4c36;
        .process {
          background-color: rgba(118, 189, 135, 0.4);
        }
      }
    }
    &.sm {
      .process-bar {
        background-color: #663256;
        .process {
          background-color: rgba(255, 125, 215, 0.4);
        }
      }
    }

    .process-bar {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 12px;
      overflow: hidden;
      .process {
        position: absolute;
        z-index: 2;
        top: 0;
        bottom: 0;
        left: 0;
      }
    }
    .info-wrapper {
      position: relative;
      z-index: 10;
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
      .info {
        flex: 1;
        display: flex;
        align-items: center;
        gap: 10px;
        @media (max-width: 975px) {
          gap: 6px;
        }
        .name {
          flex: 1;
        }
        .total {
          font-size: 17px;
          font-weight: 500;
          line-height: 23px;
        }
        @media (max-width: 975px) {
          .name {
            font-size: 14px;
          }
          .total {
            font-size: 13px;
          }
        }
      }
      .icon {
        font-size: 24px;
        flex-shrink: 0;
        transition: all 0.3s ease;
      }
    }
  }

  .episodes {
    display: none;
    opacity: 0;
    transition: all 0.3s ease;

    .episode {
      cursor: pointer;
      text-decoration: none;
      border-bottom: 1px solid rgba(29, 29, 29, 1);
      transition: all 0.3s ease;
      display: flex;
      flex-direction: column;
    }
    .quiz {
      position: relative;
      margin-top: 8px;
      padding: 24px 24px 24px 70px;
      background: #414141;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      display: flex;
      gap: 8px;
      overflow: hidden;
      @media (max-width: 975px) {
        margin-top: 0;
        padding: 50px 20px 20px;
        flex-direction: column;
      }
      .tag {
        position: absolute;
        min-width: 148px;
        transform: rotate(-30deg);

        display: flex;
        justify-content: center;
        align-items: center;

        font-weight: 600;
        font-size: 12px;
        padding: 4px 44px;
        top: 10px;
        left: -38px;
        &.passed {
          background: #327338;
          top: 10px;
          left: -44px;
        }
        &.failed {
          background: #fc434e;
          top: 10px;
          left: -38px;
        }
        &.inProgress {
          background: #2672ec;
          top: 12px;
          left: -39px;
        }
        &.notStarted,
        &.toTake {
          display: none;
        }
      }
      .quiz-info {
        overflow: hidden;
        flex: 1;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 700;
        line-height: 23px;
        gap: 8px;
        .logo {
          flex-shrink: 0;
          display: flex;
          align-items: center;
          width: 47.8px;
          height: 45px;
        }
        .quiz-title{
          @media (min-width: 768px) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 20px;
          }
        }
      }
      .button-group {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;
        @media (max-width: 768px) {
          width: 100%;
        }
        .button {
          @media (max-width: 768px) {
            flex: 1;
          }
          cursor: pointer;
          height: max-content;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          gap: 8px;
          padding: 8px 20px;
          border-radius: 6px;
          background-color: #1f1f1f;
          font-size: 14px;
          font-weight: 600;
          color: #fff;
          text-transform: uppercase;
          &.primary {
            background-color: #2672ec;
          }
        }
      }
    }
  }

  &.active {
    .title {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      .process-bar {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
      .icon {
        transform: rotate(180deg);
      }
    }

    .episodes {
      display: block;
      opacity: 1;
    }
  }
}

.episode-item-wrapper {
  padding: 12px 20px 12px 40px;
  color: #fff;
  &.watched {
    color: #717171;
    .episode-item__duration {
      color: #717171;
    }
  }

  @media (max-width: 975px) {
    padding: 12px 0;
  }

  &:hover {
    background: #1d1d1d;
  }

  .episode-item {
    display: flex;
    align-items: center;
    gap: 10px;

    &__checkmark {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__description {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.45);
      font-size: 20px;
      font-weight: 600;
      @media (max-width: 975px) {
        font-size: 16px;
      }
    }
    &__duration {
      font-size: 17px;
      color: #d2d2d2;
      line-height: 23px;
      @media (max-width: 975px) {
        font-size: 16px;
      }
    }
    &__released {
      font-size: 14px;
      font-weight: 300;
      @media (max-width: 975px) {
        display: none;
      }
    }
    &__checkmark,
    &__description,
    &__duration,
    &__released {
      flex-shrink: 0;
    }
  }
  .released-mobile {
    display: none;
    font-size: 12px;
    font-weight: 300;

    @media (max-width: 975px) {
      display: block;
    }
  }
}
